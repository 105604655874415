import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import styled from 'styled-components'

const Post = ({ data }) => {
  const { contentfulPost: post } = data
  return (
    <Layout>
      <Date>{post.publishedAt}</Date>
      <h1>{post.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }}
      />
    </Layout>
  )
}

export const postQuery = graphql`
  query posts($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      createdAt(formatString: "LLL")
      updatedAt(formatString: "LLL")
      publishedAt(formatString: "LLL")
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Post

const Date = styled.div`
  color: hsla(0, 0%, 0%, 0.5);
`
